<template>
  <div>
    <AppHeader />
    <v-container class="pa-0" fluid>
      <HowWorksSection />
      <Faq type="hiw" />
      <!--      <FAQsection type="hiw" />-->
      <FreeTrialPage @open-signup="signupActive = true" />
      <LoginAndSignUp
        :value="value"
        @closed="signupActive = false"
        :active="signupActive"
        @onsignup-click="value = 'signup'"
        @onlogin-click="value = 'login'"
      />
    </v-container>
    <AppFooter />
  </div>
</template>

<script>
import AppFooter from "@/components/AppFooter";
import AppHeader from "@/components/AppHeader";
import HowWorksSection from "@/views/HowItWorks/HowWorksSection";
import Faq from "@/components/Faq";
import FreeTrialPage from "@/components/FreeTrialPage";
import LoginAndSignUp from "@/components/LoginAndSignUp";
//import FAQsection from "@/components/FAQsection";
export default {
  components: {
    //FAQsection,
    LoginAndSignUp,
    FreeTrialPage,
    Faq,
    HowWorksSection,
    AppHeader,
    AppFooter,
  },
  data: () => ({
    signupActive: false,
    value: "signup",
  }),
  methods: {},
};
</script>

<style scoped></style>
