<template>
  <v-row justify="center" id="HIWpage">
    <v-col
      cols="auto"
      class="how-works-page-header pb-0"
      style="padding-top: 56px"
    >
      <Heading
        id="HIWpage-title"
        type="h2 MainDark"
        style="text-align: center"
        class="shadow-text"
        >How Perkfection works</Heading
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col
      cols="12"
      sm="auto"
      class="pt-2 pt-sm-3 pb-0 pb-sm-8 px-6"
      style="max-width: 724px; text-align: center"
    >
      <Texting type="p4 MainDark" style="opacity: 0.8"
        >Automate your rewards in 3 simple steps and enjoy higher conversion
        rates till the end of your campaign!</Texting
      >
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="11" order="2" order-sm="1" class="py-0 px-4">
      <v-row
        justify="center"
        class="
          pt-7 pt-sm-0
          pb-12 pb-sm-0
          mt-8 mt-sm-0
          px-4
          mb-7 mb-sm-0
          hiw-list
        "
      >
        <v-col
          v-for="(item, i) in items"
          :key="i"
          cols="auto"
          style="max-width: 333px; text-align: center"
        >
          <v-row justify="center">
            <v-col cols="auto" style="padding-bottom: 0">
              <Heading
                type="h1-3 grey10"
                style="z-index: 2; position: relative"
                >{{ i + 1 }}</Heading
              >
              <!--              <div class="number-square"></div>-->
              <Images
                class="number-square"
                name="https://res.cloudinary.com/dyf7aejih/image/upload/v1638344824/images/hiw_list_icon_nozvdo.svg"
              />
            </v-col>
            <div style="width: 100%"></div>
            <v-col cols="auto" style="padding-top: 0">
              <Texting type="p3l MainDark" style="opacity: 0.8">{{
                item.text
              }}</Texting>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <div style="width: 100%"></div>
    <v-col cols="11" order="1" order-sm="2" class="py-0 px-4">
      <v-row justify="center">
        <v-col cols="12" class="hiw-video px-0">
          <div
            @mouseover="hoverActive = true"
            @mouseleave="hoverActive = false"
            @click="playVideo"
            class="pointer video-top-section"
          >
            <div>
              <Images
                width="100%"
                height="100%"
                class="video-top-section-image"
                name="hiw_video_top.svg"
              />
            </div>
          </div>
          <div
            @mouseover="hoverActive = true"
            @mouseleave="hoverActive = false"
            @click="playVideo"
            class="pointer video-cont"
          >
            <div class="pa-0" style="position: relative; z-index: 10">
              <div v-if="!showvid" @click="playVideo" id="videocover">
                &nbsp;
              </div>
              <video
                @playing="onPlaying"
                preload="none"
                poster="https://res.cloudinary.com/dyf7aejih/image/upload/v1638543811/images/Perks_Setup_-_Thumbnail_idcadw.png"
                id="hiw_video"
                width="100%"
                style="border-radius: 0 0 7px 7px"
                playsinline
                :controls="showvid ? true : false"
              >
                <source
                  src="https://storage.googleapis.com/btest6.ao.am/Perkfection-How-it-works.webm"
                  type="video/webm"
                />
                <source
                  src="https://storage.googleapis.com/btest6.ao.am/Perkfection-How-it-works.mp4"
                  type="video/mp4"
                />
                Your browser does not support the video tag.
              </video>
              <!--              <Images
                v-show="!showvid"
                style="position: relative; top: 4px"
                name="https://res.cloudinary.com/dyf7aejih/image/upload/v1638543811/images/Perks_Setup_-_Thumbnail_idcadw.png"
                width="100%"
                height="100%"
              />-->
            </div>
          </div>
          <div
            v-if="!showvid"
            @mouseover="hoverActive = true"
            @mouseleave="hoverActive = false"
            id="howWorksPage-play-icon-2f162db8"
            :class="{
              'play-icon-hovered': hoverActive,
              'play-icon': !hoverActive,
            }"
            @click="playVideo"
          ></div>
          <Images
            name="hiw_back1.png"
            class="d-none d-md-flex"
            style="position: absolute; bottom: 155px; left: -95px"
          />
          <Images
            name="hiw_back2.png"
            class="d-none d-md-flex"
            style="position: absolute; bottom: 31px; left: -75px"
          />
          <Images
            name="hiw_back3.png"
            class="d-none d-md-flex"
            style="position: absolute; top: 74px; right: 93px"
          />
          <Images
            name="hiw_back4.png"
            class="d-none d-md-flex"
            style="position: absolute; top: 44px; right: -105px; z-index: 2"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import Heading from "@/components/Heading";
import Texting from "@/components/Texting";
import Images from "@/components/MyImages";
import myServices from "@/services/resource";

export default {
  components: { Images, Texting, Heading },
  data: () => ({
    showvid: false,
    pageOfsset: null,
    hoverActive: false,
    vidPaused: false,
    items: [
      {
        text: "Activate Perkfection Monitoring of your campaign.",
      },
      {
        text: "Select your rewards’ limits and top-up amounts.",
      },
      {
        text: "That’s it! No 3rd step — your rewards are taken care of!",
      },
    ],
  }),
  watch: {
    vidPaused: function (val) {
      if (val) {
        console.log("paused");
      }
    },
  },
  created() {
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  updated() {
    console.log("updated");
  },
  methods: {
    onPlaying(e) {
      console.log(e);
      let vid = document.getElementById("hiw_video");
      this.vidPaused = vid.paused;
    },
    playVideo() {
      myServices.myAnalyticsEvents(this, "How it works", "Video play click");
      let vid = document.getElementById("hiw_video");
      this.showvid = true;
      this.pageOfsset = window.pageYOffset;
      vid.play();
    },
    handleScroll() {
      if (Math.abs(window.pageYOffset - this.pageOfsset) > 500) {
        let vid = document.getElementById("hiw_video");
        this.showvid = false;
        vid.pause();
      }
    },
  },
};
</script>

<style scoped>
.hiw-video {
  max-width: 786px;
  padding-bottom: 90px;
  padding-top: 74px;
  position: relative;
  z-index: 5;
}
.number-square {
  z-index: 1;
  position: relative;
  bottom: 58px;
  left: 17px;
}
.browser-circle-icon {
  border-radius: 50%;
  width: 11px;
  height: 11px;
}
#howWorksPage-play-icon-2f162db8 {
  width: 80px;
  height: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 150;
  cursor: pointer;
  max-width: 102px;
}
.play-icon {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1638136797/images/play_icon1_sdmale.svg");
  background-size: contain;
}
.play-icon-hovered {
  background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1638136797/images/play_icon1_hovered_lke8yu.svg");
  width: 102px !important;
  height: 102px !important;
}
.video-cont {
  z-index: 100 !important;
  position: relative;
  filter: drop-shadow(0px 22.6763px 100.042px rgba(0, 0, 0, 0.07))
    drop-shadow(0px 8.27721px 36.5171px rgba(0, 0, 0, 0.0482987))
    drop-shadow(0px 4.01843px 17.7284px rgba(0, 0, 0, 0.0389404))
    drop-shadow(0px 1.96991px 8.69078px rgba(0, 0, 0, 0.0310596))
    drop-shadow(0px 0.778906px 3.43635px rgba(0, 0, 0, 0.0217013));
}

@media only screen and (max-width: 599px) {
  #HIWpage {
    background-color: #f9f9f9;
  }
  .hiw-video {
    padding-top: 12px !important;
    padding-bottom: 70px !important;
  }
  .video-cont {
    filter: drop-shadow(0px 9.89563px 43.6572px rgba(0, 0, 0, 0.07))
      drop-shadow(0px 3.61207px 15.9356px rgba(0, 0, 0, 0.0482987))
      drop-shadow(0px 1.75359px 7.73643px rgba(0, 0, 0, 0.0389404))
      drop-shadow(0px 0.859643px 3.79254px rgba(0, 0, 0, 0.0310596))
      drop-shadow(0px 0.339904px 1.49958px rgba(0, 0, 0, 0.0217013));
  }
  .hiw-list {
    background: #fbfbfb;
    box-shadow: 0 0 35px rgba(154, 161, 171, 0.15);
    border-radius: 22px;
    z-index: 150;
    position: relative;
  }

  .h2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 32px;
  }
  .p4 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
  }
  .how-works-page-header {
    padding-top: 64px !important;
  }
  .hiw-video {
    padding-bottom: 0 !important;
  }
  #howWorksPage-play-icon-2f162db8 {
    top: 54% !important;
    max-width: 45px !important;
    max-height: 45px !important;
    background-size: contain;
  }
  #howWorksPage-play-icon-2f162db8.play-icon-hovered {
    max-width: 55px !important;
    max-height: 55px !important;
  }
  .play-icon-hovered {
    background-image: url("https://res.cloudinary.com/dyf7aejih/image/upload/v1641560866/images/play-icon-mobile-circle-outline_dd0vkq.svg");
    background-position: center;
    background-size: contain;
  }
  .video-top-section-image {
    top: 0 !important;
  }
  .video-top-section {
    max-height: 26px;
  }
}
@media only screen and (max-width: 484px) {
  .video-top-section {
    max-height: 19px;
  }
}
#videocover {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.video-top-section-image {
  position: relative;
  top: 13px;
  z-index: 5;
}
</style>
